.btn {
    transition: ease-in-out 400ms all;
    line-height: 24px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Lato", sans-serif;
}