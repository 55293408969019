.customers {
    position: relative;
}

.notification_modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    inset: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.notification_modal .dashboard_header_text {
    font-size: 18px;
}

@media(max-width:600px) {
    .notification_modal {
        padding: 50px 15px;
    }
}