.add_food_item_container {
    font-family: "Lato", sans-serif;
}

.go_back {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
}

.add_food_item_header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add_food_item_container .add_food_item_form {
    align-items: flex-end;
}

.add_food_item_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 100px;
    justify-content: space-between;
    row-gap: 50px;
    column-gap: 30px;
    padding-bottom: 20px;
}

label {
    border-radius: 8px;
}

.add_food_item_form .food_item_inputs {
    width: 100%;
}



.add_food_item_form_item_one h4 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: var(--color3);
    margin-bottom: 10px;
}

.add_food_item_form_item_one {
    display: flex;
    gap: 15px;
    align-items: center;
}

.add_food_item_form_item_two {
    justify-self: flex-end;
}

.add_food_item_form_labels {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: var(--color1);
    margin-bottom: 10px;
}

.add_food_item_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}