.details_view {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}

.details_view>div {
    width: 50%;
    background-color: white;
    border-radius: 14px;
    padding: 20px;
    padding-left: 30px;
}

.details_view .dashboard_header_text {
    font-size: 17px;
    margin-bottom: 20px;

}

.order_details_flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 25px;
}

.details_view .dashboard_header_text {
    font-size: 16px;
    color: var(--color1);
    font-weight: 500;
}

.order_details_flex>div {
    background-color: var(--color2);
    height: 40px;
    width: 188px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    border-radius: 10px;
}

.details_view p {
    color: var(--color1);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.order_details_flex .table_body_header_text {
    font-size: 14px;
    font-weight: 500;
}

.details_view .profile {
    margin-bottom: 15px;
}

.details_view>div:nth-child(2) {
    height: 337px;
}

.details_view>div:nth-child(2) p {
    margin-bottom: 20px;
}

.order_details_container .food_item_table {
    margin-top: 20px;
}

.order_details_container .table_body_header>div,
.order_details_container .table_body_body>div {
    margin: 0 10px;
    width: 14%;
}


.order_details_container .table_body_header_item_2 {
    width: 15% !important;
}

.order_details_container .table_body_header_item_3 {
    width: 20% !important;
}

.order_details_container .table_body_header_text {
    text-align: start;
}

.order_details_container .food_item_paragraphs {
    text-align: start;
}

.order_details_container .table_body_header {
    margin-bottom: 10px;
}