.order_statistics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.order_statistics_item {
    background-color: white;
    border: 1px solid var(--color2);
    width: 50%;
    padding: 15px;
    border-radius: 8px;
}

@media(max-width:600px) {
    .order_statistics {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 15px;
    }

    .order_statistics>div {
        width: 100%;
    }
}