.dashboard {
    font-family: "Lato", sans-serif;
    height: 100vh;
    overflow: hidden;
}

.main_sect {
    display: flex;
    margin-top: 70px;
    height: 100%;
    overflow-y: hidden;
}


.dashboard_nav {
    background-color: var(--color3);
    width: 19%;
    padding: 20px 0;
    padding-left: 20px;
    overflow-y: scroll;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 100px;
}

.dashboard_nav::-webkit-scrollbar {
    display: none;
}

.nav_list_category {
    color: var(--color4);
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
}

.nav_items {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
    text-decoration: none;
    padding-left: 20px;
}

.nav_items .nav_list_category {
    margin: 0;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
}

.nav_image {
    width: 18px;
    height: 18px;
}

.routes_views {
    background-color: #F7F7F7;
    padding: 40px 20px;
    width: 81%;
    overflow-y: scroll;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 100px;
}

.routes_views::-webkit-scrollbar {
    display: none;
}

.active_tab {
    transition: all ease-in-out 350ms;
    background-color: white;
    height: 41px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.notification_container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 450px;
}

.notification_container .add_food_item_header {
    justify-content: space-between;
    margin-bottom: 30px;
}

.notification_container .goback {
    width: 42px;
    height: 42px;
}

.notification_container .order_details_flex {
    grid-template-columns: 1fr;
}

.notification_container .order_details_flex>div {
    width: 100%;
}

.notification_container .add_food_item_form_labels {
    margin-bottom: 20px;
}

.notification_container_textarea {
    background-color: var(--color2);
    border: none;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    outline: none;
    padding: 20px;
}

.notification_flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    margin-top: 20px;
}

.close_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 30px;
}

.close_container svg {
    margin-bottom: 30px;
}

.close_container h3 {
    line-height: 30px;
    font-size: 20px;
    font-weight: 500;

}

.close_container img {
    height: 100px;
    width: 100px;
    margin-bottom: 20px;
}

.close_container p {
    font-size: 14px;
    font-weight: 400;
    color: var(--color1);
    margin: 10px 0;
}

@media(max-width:600px) {

    .dashboard_nav {
        width: 80%;
        position: fixed;
        inset: 0;
        padding-bottom: 40px;
        transition: transform 350ms ease-in-out;
        transform: translateX(400px);
        z-index: 999;
    }

    .show_nav {
        transform: translateX(0px) !important;
    }

    .routes_views {
        width: 100%;
    }

    .notification_container {
        width: 400px;
    }
}