@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

:root {
  --color1: #313332;
  --color2: #fff;
  --color3: black;
  --color4: #999DA1;
  --color5: #5A5C5B;
  --secondary-color: black;
  --other-color: #FC6121;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
}

a,
li {
  list-style: none;
  text-decoration: none;
}

.list_loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

input,
select {
  font-size: 16px !important;
}